import { Component, OnInit } from '@angular/core';
import {
  Router,
  NavigationStart,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  ActivatedRoute,
  Event
} from '@angular/router';
import { Location } from '@angular/common';
import { DataShareService } from './landing-layout/shared/data-share.service';
import { CrudService } from './landing-layout/shared/crud.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Title } from '@angular/platform-browser';
import { environment } from '../environments/environment';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'synciit-public';
  public url;
  checkCoockie: boolean;
  hostName: any = '';
  host: any = '';
  public currentPage: any;
  constructor(
    private all: CrudService,
    private router: Router,
  ) {
    if (environment.production) {
      const script = document.createElement('script');
      script.async = true;
      script.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.google_code;
      document.head.prepend(script);
    }

    this.all.getAccessToken().subscribe(res => {
      const accessToken = res['access_token'];
      console.info('--------------------------')
      console.info('ACCESS_TOKEN =>',accessToken)
      console.info('--------------------------')
      localStorage.setItem('accessToken', btoa(accessToken));
    });
    this.checkCoockie = this.checkCookie();
  }

  ngOnInit() {
  }

  checkCookie() {
    let cookieEnabled = navigator.cookieEnabled;
    if (!cookieEnabled) {
      document.cookie = 'testcookie';
      cookieEnabled = document.cookie.indexOf('testcookie') !== -1;
    }
    return cookieEnabled;
  }
}
