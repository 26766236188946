import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataShareService } from '../shared/data-share.service';
import { EncrDecrService } from '../shared/EncrDecrService.service';
import { PropertyService } from '../shared/property.service';
import _ from 'lodash'
import { ToastrService } from 'ngx-toastr';
import {OwlOptions} from "ngx-owl-carousel-o";
import { CrudService } from '../shared/crud.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-saved-searches',
  templateUrl: './saved-searches.component.html',
  styleUrls: ['./saved-searches.component.css']
})
export class SavedSearchesComponent implements OnInit {
  public user;
  public savedSearchList = []
  public hasNoPropertyRecords = false;
  public _get = _.get
  public currentPage = null
  public propertiesList = []
  public lat: number = -33.8781954;
  public long: number = 151.2031137;
  public premiumTradersList = []
  public imageUrl = environment.imageUrl;
  public traderImageURL = environment.imageUrl + 'user_image';
  public propertyImageURL = environment.imageUrl + 'property_image/'

  public customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    autoplay: false,
    navSpeed: 700,
    navText: [
      "&#8249;", "&#8250;"
    ],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: true
  };

  constructor(
    private dataShare: DataShareService,
    private EncrDecr: EncrDecrService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private propertyService : PropertyService,
    private toastr: ToastrService,
    private all: CrudService,
    ) {
      this.currentPage = `/${(this.router.url).split('/')[1]}`;
      this.dataShare.loginUser.subscribe((data) => {
        if (data) {
          this.user = localStorage.getItem('user') ? this.EncrDecr.getEncrypt(localStorage.getItem('user')) : null;
          console.log('this.user :: 1 => ', this.user);
        } else {
          this.user = localStorage.getItem('user') ? this.EncrDecr.getEncrypt(localStorage.getItem('user')) : null;
          console.log('this.user :: 1 => ', this.user);
        }

      });
  }

  createSearchAraay(savedsearchList) {
    if(savedsearchList.code !== 200){
      this.hasNoPropertyRecords = true
      this.savedSearchList = []
      this.spinner.hide('outer');
    }
    this.hasNoPropertyRecords = false
    let list = savedsearchList.data
    list.forEach(item => {
      let searchfilter = []
      searchfilter.push(item.payload.listingType === 'sale' ? 'Buy' : item.payload.listingType);
      searchfilter.push(this._get(item,"payload.maxPrice","Any Price"))
      this._get(item,"payload.propertyTypes",[]).forEach(pItem => searchfilter.push(pItem))
      this._get(item,"payload.propertyFeatures",[]).forEach(fItem => searchfilter.push(fItem))
      this._get(item,"payload.propertyEstablishedType",null) && searchfilter.push(this._get(item,"payload.propertyEstablishedType",null))
      this._get(item,"payload.keywords",[]).forEach(kItem => searchfilter.push(kItem));
      item.payload.searchfilter = searchfilter
    });
    this.savedSearchList = list
    this.spinner.hide('outer');
    console.info('--------------------------')
    console.info('savedsearchList =>',list)
    console.info('--------------------------')
  }

  createPropertyArray(res){
    console.info('--------------------------')
    console.info('FAV_PROPERTIES_LIST =>',res)
    console.info('--------------------------')
    if(res.code !== 200) {
      this.hasNoPropertyRecords = true
      this.spinner.hide('outer');
    }
    this.hasNoPropertyRecords = false
    let list = res.data
    this.propertiesList = list
    this.getInitMap(list)
    this.spinner.hide('outer');
  }

  checkRemoveSearch(res){
    if(res.code === 200) {
      this.toastr.success('Search remove Successfully!', 'Success!', {
        timeOut: 3000
      });
      this.spinner.hide('outer');
      this.getAllSaveSearchProperties()
    } else {
      this.spinner.hide('outer');
      this.toastr.error('Something went worng. Please try again!', 'Error!', {
        timeOut: 3000
      });
    }
  }

  handleRemoveSelectedSearch(searchId){
    console.info('--------------------------')
    console.info('SEARCH_ID =>',searchId)
    console.info('--------------------------')
    this.spinner.show('outer');
    this.propertyService.deleteSavedSearch(searchId).toPromise().then(res => {
      this.checkRemoveSearch(res)
    })
  }

  getAllSaveSearchProperties(){
    let userId = this.user.data._id
    this.spinner.show('outer');
    this.propertyService.getSavedSearchProperties(userId).toPromise().then(res => {
      this.hasNoPropertyRecords = false
      this.createSearchAraay(res)
    })
  }

  getFavPropertiesList(){
    let userId = this.user.data._id
    this.spinner.show('outer');
    this.propertyService.getFaviouratePropertyList(userId).toPromise().then(res => {
      this.hasNoPropertyRecords = false
      this.createPropertyArray(res)
    })
  }

  followProperty(propertyId, status){
    let userId = this.user.data._id
    let search_obj = JSON.parse(localStorage.getItem('search_obj'));

    this.propertyService.addToFavouritesProperty(userId,status,propertyId).toPromise().then(async (res) => {
      this.getFavPropertiesList()
    })
  }

  getInitMap(propertiesList = []){
    const myLatLng = { lat: -25.363, lng: 131.044 };
    const map = new google.maps.Map(
      document.getElementById("following-properties-map") as HTMLElement,
      {
        zoom: 4,
        center: myLatLng,
      }
    );
    propertiesList.forEach(item => {
      new google.maps.Marker({
        position: { lat: parseFloat(item.latitude), lng: parseFloat(item.longitude) },
        map,
        title: item.address,
      });
    })

  }

  getFollowingTradersList(){
    this.spinner.show('outer');
    let userId = this.user.data._id
    this.all.post('getAllSavedTraders', {user_id: userId}).subscribe(async (res: any) => {
      if(res.code === 200) {
        this.premiumTradersList = res.data
        this.spinner.hide('outer');
        console.info('--------------------------')
        console.info('this.premiumTradersList =>',this.premiumTradersList)
        console.info('--------------------------')
      } else {
        this.premiumTradersList = []
        this.spinner.hide('outer');
      }
    })
  }

  unfollowTrader(traderId) {
    this.spinner.show('outer');
    let userId = this.user.data._id
    let reqData = {
      fav_by: userId,
      fav_status: 2,
      fav_to: traderId
    }
    this.all.post('addToFavouritesUser',reqData).subscribe(async (res) => {
      this.spinner.hide('outer');
      this.getFollowingTradersList();
    })
  }

  ngOnInit() {
    if(this.user && this.user.data) {
      if(this.currentPage === '/saved-searches'){
        this.getAllSaveSearchProperties()
      } else if(this.currentPage === '/follow-properties') {
        this.getFavPropertiesList()
      } else if(this.currentPage === '/follow-traders') {
        this.getFollowingTradersList()
      }
    }
  }

}
