import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tems-and-condition',
  templateUrl: './tems-and-condition.component.html',
  styleUrls: ['./tems-and-condition.component.css']
})
export class TemsAndConditionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
