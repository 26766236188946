import { Component, OnInit, OnDestroy, AfterViewInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { PropertyService } from "../shared/property.service";
import { OwlOptions } from "ngx-owl-carousel-o";
import {
  Validators,
  FormControl,
  FormGroup,
  FormBuilder,
  ValidatorFn,
} from "@angular/forms";
import { MessageService } from "primeng/components/common/messageservice";
import { CrudService } from "../shared/crud.service";
import { DataShareService } from "../shared/data-share.service";
import { NgxSpinnerService } from "ngx-spinner";
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from "@angular/animations";
import { Title } from "@angular/platform-browser";
import { Lightbox as box } from "ngx-lightbox";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import * as moment from "moment";
import { Loader, LoaderOptions } from "google-maps";
import _get from "lodash/get";
import _uniqBy from "lodash/uniqBy";
import { EOIService } from "../shared/eoi.service";
import { EncrDecrService } from "../shared/EncrDecrService.service";
import { ToastrService } from "ngx-toastr";
import _filter from "lodash/filter";
import _findIndex from "lodash/findIndex";

const MyAwesomeRangeValidator: ValidatorFn = (fg: FormGroup) => {
  const start = fg.get("from").value;
  const end = fg.get("to").value;
  return start !== null && end !== null && parseInt(start) < parseInt(end)
    ? null
    : { range: true };
};

@Component({
  selector: "app-property-list-details",
  templateUrl: "./property-list-details.component.html",
  styleUrls: ["./property-list-details.component.css"],
  animations: [
    trigger("slideOut", [
      transition(":enter", [
        style({ transform: "translateY(-100%)" }),
        animate("2000ms ease-in", style({ transform: "translateY(50%)" })),
      ]),
    ]),
    trigger("slideIn", [
      transition(":enter", [
        style({ transform: "translateY(-100%)" }),
        animate("2000ms ease-in", style({ transform: "translateY(50%)" })),
      ]),
    ]),
  ],
})
export class PropertyListDetailsComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  public user: any;
  public get = _get;
  public findIndex = _findIndex;
  public uniqBy = _uniqBy;
  public propertyDetails: any;
  public propertyRegisterText: string =
    "Register your  interest in this property here";
  public adData: any;
  imageUrl = environment.imageUrl;
  public apiUrl = environment.apiUrl;
  public agentDetails = [];
  public agencyDetails = {};
  public similarProperties = [];
  public toggelText = "READ MORE";
  public display: boolean = false;
  // public activeEOI = true;
  public displayEOI: boolean = false;
  public displayBuy: boolean = false;
  public displayRent: boolean = false;
  public displaySortStay: boolean = false;
  public displayHouseSwap: boolean = false;
  public displayMapDialog: boolean = false;
  public displayClaimButton: boolean = true;
  public selectedEnquries: string[] = ["Could I have a price guide?"];
  public selectedAreYou: string = "";
  public form: FormGroup = new FormGroup({});
  public buy_form: FormGroup = new FormGroup({});
  public rent_form: FormGroup = new FormGroup({});
  public sort_stay_form: FormGroup = new FormGroup({});
  public house_swap_form: FormGroup = new FormGroup({});
  public eoi_form: FormGroup = new FormGroup({});
  public isNullAllValue = false;
  public formData: any;
  public isFormSubmited = false;
  public enquiriesErr = false;
  public paramsSub;
  public propertyId: number;
  public propertyImages: any[] = [];
  public lat: number = 51.678418;
  public long: number = 7.809007;
  public expressionText: string = "EXPRESSION OF INTEREST";
  public numberOfDaysList = [];
  public currentDate = new Date();
  public options: LoaderOptions = {
    /* todo */
  };
  public loader = new Loader(
    "AIzaSyB_L4pPNYwWjRXJM_lFvRfKWCNK2L3oNDs",
    this.options
  );
  public activeMapTab = "mapview";
  public streetViewMap;
  public customStyle;
  public premiumTradersList = [];
  public serviceCategories = [];
  // carousel settings
  public search_obj = {};
  public req_isFormSubmited;
  public customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    autoplay: false,
    navSpeed: 700,
    navText: ["&#8249;", "&#8250;"],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      740: {
        items: 3,
      },
      940: {
        items: 4,
      },
    },
    nav: true,
  };

  public amenities = [];

  constructor(
    private route: ActivatedRoute,
    private _lightbox: box,
    private propertyService: PropertyService,
    public fb: FormBuilder,
    private messageService: MessageService,
    private service: CrudService,
    private spinner: NgxSpinnerService,
    private dataShare: DataShareService,
    private datashare: DataShareService,
    private titleService: Title,
    private http: HttpClient,
    private eoiService: EOIService,
    private EncrDecr: EncrDecrService,
    private toastr: ToastrService,
    private router: Router,
    private all: CrudService
  ) {
    this.titleService.setTitle(this.route.snapshot.data["title"]);
    this.spinner.show("outer");
    window.scrollTo(0, 0);
    this.ServiceCategoryList();
    this.dataShare.isDisplayClaim.subscribe((data) => {
      this.displayClaimButton = data;
    });
    this.dataShare.toggleDisplayClaimButton(true);
    this.dataShare.loginUser.subscribe((data) => {
      if (data) {
        console.log("1 => ");

        this.user = localStorage.getItem("user")
          ? this.EncrDecr.getEncrypt(localStorage.getItem("user"))
          : null;
        console.log("this.user => ", this.user);
      } else {
        this.user = localStorage.getItem("user")
          ? this.EncrDecr.getEncrypt(localStorage.getItem("user"))
          : null;
        console.log("this.user => ", this.user);
      }
    });

    this.search_obj = JSON.parse(localStorage.getItem("search_obj"));
    console.log("search_obj => ", this.search_obj);
    let postcode = this.search_obj["locations"][0]["postCode"];
    let state = this.search_obj["locations"][0]["state"];
    if (state == "NSW") {
      state = "New South Wales";
    }
    if (state == "ACT") {
      state = "Australian Capital Territory";
    }
    if (state == "VIC") {
      state = "Victoria";
    }
    if (state == "QLD") {
      state = "Queensland";
    }
    if (state == "SA") {
      state = "South Australia";
    }
    if (state == "WA") {
      state = "Western Australia";
    }
    if (state == "TAS") {
      state = "Tasmania";
    }
    if (state == "NT") {
      state = "Northern Territory";
    }
    const obj = {};
    if (postcode) {
      console.log("postcode :: condition => ");
      obj["searchtext"] = postcode;
    }
    if (state) {
      console.log("postcode :: state => ");
      obj["searchState"] = state;
    }
    console.log("obj => ", obj);
    this.service
      .post(
        "advertiseList",
        obj
        // {
        //   searchtext: postcode,
        //   searchState: state
        // }
      )
      .toPromise()
      .then(async (res) => {
        console.log("res :: AD LIST ==============================> ", res);
        if (res["data"] && res["data"].length > 0) {
          this.adData = this.random_item(res["data"]);
        }
        console.log("adData => ", this.adData);
        if (this.adData && this.adData._id) {
          await this.viewedAdvertise(this.adData._id);
        }
      });

    this.paramsSub = this.route.params.subscribe((params) => {
      this.propertyId = params.id;
      this.propertyImages = [];
      const propertyDetails = this.route.snapshot.data["propertyDetail"].data;
      console.log("propertyDetails ===================> ", propertyDetails);
      this.lat = parseFloat(propertyDetails.latitude);
      this.long = parseFloat(propertyDetails.longitude);
      const agency_id = propertyDetails["advertiserIdentifiers"]
        ? propertyDetails["advertiserIdentifiers"].advertiserId
        : null;
      this.amenities = propertyDetails["amenities"];

      this.getInitStreetMap();

      // for similar properties
      const similar_properties = this.route.snapshot.data["similarProperties"];
      this.similarProperties = this.createPropertyAraay(similar_properties);

      // for agents
      const contactIds = propertyDetails["advertiserIdentifiers"]
        ? propertyDetails["advertiserIdentifiers"].contactIds
        : [];
      this.propertyDetails = propertyDetails;
      if (
        this.propertyDetails.claim_status &&
        (this.propertyDetails.claim_status === "pending" ||
          this.propertyDetails.claim_status === "accepted")
      ) {
        this.dataShare.toggleDisplayClaimButton(false);
        this.displayClaimButton = false;
      }

      this.propertyService.getAgencyDetails(agency_id).subscribe((a_d) => {
        let agencyObj = {
          name: a_d["name"] ? a_d["name"] : "",
          id: a_d["id"] ? a_d["id"] : "",
        };
        this.propertyDetails["agencyDetails"] = agencyObj;
        if (
          contactIds &&
          contactIds.length > 0 &&
          a_d["agents"] &&
          a_d["agents"].length > 0
        ) {
          let agentsObj = [];
          const agentArray = a_d["agents"];
          for (const agent of agentArray) {
            if (contactIds.indexOf(agent.id) !== -1) {
              agentsObj.push(agent);
            }
          }
          this.propertyDetails["agentDetails"] = agentsObj;
        }
      });
      const len = propertyDetails["media"].length;
      if (len < 5) {
        let cnt = 5 - len;
        for (let i = 0; i < len; i++) {
          let obj = {
            source: `${propertyDetails["media"][i].url}/800x600`,
            thumbnail: `${propertyDetails["media"][i].url}/800x600`,
            src: `${propertyDetails["media"][i].url}`,
            title: ``,
          };
          this.propertyImages.push(obj);
        }
        for (let i = 0; i < cnt; i++) {
          let obj = {
            source: `${propertyDetails["media"][i].url}/800x600`,
            thumbnail: `${propertyDetails["media"][i].url}/800x600`,
            src: `${propertyDetails["media"][i].url}`,
            title: ``,
          };
          this.propertyImages.push(obj);
        }
      } else if (len >= 5) {
        for (let i = 0; i < 5; i++) {
          let obj = {
            source: `${propertyDetails["media"][i].url}/800x600`,
            thumbnail: `${propertyDetails["media"][i].url}/800x600`,
            src: `${propertyDetails["media"][i].url}`,
            title: ``,
          };
          this.propertyImages.push(obj);
        }
      }
    });

    this.premiumTradersList = this.uniqBy(
      this.get(this.propertyDetails, "tradersDetails", []),
      (obj) => [this.get(obj, "_id", "")].join()
    );
    console.info("--------------------------");
    console.info("this.propertyDetails. =>", this.propertyDetails);
    console.info("--------------------------");
    // for enquiry form
    this.formData = {};
    const pattern = new RegExp(
      "^([A-Za-z0-9_\\-\\.])+\\@([A-Za-z0-9_\\-\\.])+\\.([A-Za-z]{2,5})$"
    );
    this.form = this.fb.group({
      first_name: new FormControl(
        "",
        Validators.compose([Validators.required, this.noWhitespaceValidator])
      ),
      last_name: new FormControl(
        "",
        Validators.compose([Validators.required, this.noWhitespaceValidator])
      ),
      email: new FormControl(
        "",
        Validators.compose([Validators.required, Validators.pattern(pattern)])
      ),
      phone: new FormControl("", Validators.pattern("[0-9]{10}")),
      post_code: new FormControl("", Validators.pattern("[0-9]{4}")),
      property_query: new FormControl(""),
      enquiries: new FormControl(""),
    });

    this.buy_form = this.fb.group(
      {
        are_you: new FormControl("", Validators.compose([Validators.required])),
        from: new FormControl(
          "",
          Validators.compose([Validators.required, Validators.min(1)])
        ),
        to: new FormControl("", Validators.compose([Validators.required])),
      },
      { validators: MyAwesomeRangeValidator }
    );

    this.rent_form = this.fb.group({
      are_you: new FormControl("", Validators.compose([Validators.required])),
      amount_per_week: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
    });

    this.sort_stay_form = this.fb.group(
      {
        num_days: new FormControl(
          "",
          Validators.compose([Validators.required])
        ),
        num_guests: new FormControl(
          "",
          Validators.compose([Validators.required])
        ),
        from_date: new FormControl(
          "",
          Validators.compose([Validators.required])
        ),
        to_date: new FormControl("", Validators.compose([Validators.required])),
      },
      { validator: this.dateLessThan("from_date", "to_date") }
    );

    this.house_swap_form = this.fb.group({
      address: new FormControl("", Validators.compose([Validators.required])),
      has_own_property: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
    });

    this.eoi_form = this.fb.group({
      activeEOI: new FormControl("", Validators.compose([Validators.required])),
      buy_price: new FormControl(
        "",
        Validators.compose([Validators.pattern("[0-9]*")])
      ),
      rent_per_week: new FormControl(
        "",
        Validators.compose([Validators.pattern("[0-9]*")])
      ),
      short_stay_price_per_night: new FormControl(
        "",
        Validators.compose([
          Validators.pattern("[0-9]*"),
          Validators.max(10),
          Validators.min(1),
        ])
      ),
      swap_per_night: new FormControl(
        "",
        Validators.compose([Validators.pattern("[0-9]*")])
      ),
    });

    this.numberOfDaysList = [];
    for (let index = 1; index <= 10; index++) {
      this.numberOfDaysList.push(index);
    }
  }

  dateLessThan(from: string, to: string) {
    return (group: FormGroup): { [key: string]: any } => {
      let f = group.controls[from];
      let t = group.controls[to];
      if (f.value > t.value) {
        return {
          dates: "Date from should be less than Date to",
        };
      }
      return {};
    };
  }

  ServiceCategoryList() {
    this.all.get("getServiceCategory").subscribe(
      (res) => {
        console.log("res:: service categories => ", res);
        if (res && res["data"]) {
          this.serviceCategories = res[`data`];
        } else {
          this.serviceCategories = [];
        }
      },
      (err) => {
        console.log("err => ", err);
        this.serviceCategories = [];
      }
    );
  }

  // white space validation
  noWhitespaceValidator(control: FormControl) {
    let isWhitespace = (control.value || "").trim().length === 0;
    let isValid = !isWhitespace;
    return isValid ? null : { required: true };
  }

  // random
  random_item(items) {
    return items[Math.floor(Math.random() * items.length)];
  }

  // Viewed Ad
  viewedAdvertise(id) {
    // this.http.get('https://api.ipify.org/?format=json').subscribe((res: any) => {
    //   console.log('res for ip => ', res);
    //   const ipAddress = res.ip;
    //   if (ipAddress) {
    //     console.log('value for viewwd ad api :: userIp , adId => ', ipAddress, id);
    //     this.service.post('viewedAdvertise', {
    //       userIP: ipAddress,
    //       adId: id
    //     }).subscribe((resp) => {
    //       console.log('res :: viewed ad api=> ', resp);
    //     });
    //   }
    // });
  }

  // clicked Ad
  clickedAd(id) {
    console.log("id => ", id);
    this.service
      .post("clickedAdvertise", {
        adId: id,
      })
      .subscribe((res) => {
        console.log("res :: clicked ad api=> ", res);
      });
  }

  // validation for integer number
  restrictAlphabets(e) {
    var x = e.which || e.keycode;
    if ((x >= 48 && x <= 57) || x === 8 || (x >= 35 && x <= 40) || x === 46) {
      return true;
    } else {
      return false;
    }
  }

  // extract property data from DOmain API
  createPropertyAraay(properties) {
    let list = [];
    for (const p of properties) {
      if (p.hasOwnProperty("listing") && list.length < 3) {
        if (p.id !== this.propertyId) {
          list.push(p.listing);
        }
      } else if (p.hasOwnProperty("listings") && list.length < 3) {
        if (p.listings.length > 0) {
          for (const ele of p.listings) {
            if (list.length < 3) {
              if (ele.id !== this.propertyId) {
                list.push(ele);
              }
            } else {
              return list;
            }
          }
        }
      } else if (list.length > 2) {
        return list;
      }
    }
    // return list;
  }

  // toggle for read more feature
  toggle(text) {
    if (text === "READ MORE") {
      this.toggelText = "READ LESS";
    } else {
      this.toggelText = "READ MORE";
    }
  }

  // dialogue button
  showDialog(agent: Object) {
    console.info("--------------------------");
    console.info("showDialog call =>", agent);
    console.info("--------------------------");
    this.display = true;
    this.formData.agent_email_id = agent["email"];
    this.formData.agent_id = `${agent["id"]}`;
    this.formData.property_url = this.propertyDetails["seoUrl"];
    this.formData.recivername = `${agent["firstName"]} ${agent["lastName"]}`;
  }

  initialDialog() {
    this.displayBuy = false;
    this.displayRent = false;
    this.displaySortStay = false;
    this.displayHouseSwap = false;
    this.displayEOI = false;
  }

  handleSubmitEOI() {
    this.isNullAllValue = false;
    this.isFormSubmited = true;

    let formData = this.eoi_form.value;
    let eoiType = formData.activeEOI
      ? formData.activeEOI.length > 0
        ? formData.activeEOI[0]
        : ""
      : "";

    let buy_price = formData.buy_price;
    let rent_per_week = formData.rent_per_week;
    let short_stay_price_per_night = formData.short_stay_price_per_night;
    let swap_per_night = formData.swap_per_night;

    if (
      buy_price === null &&
      rent_per_week === null &&
      short_stay_price_per_night === null &&
      swap_per_night === null
    ) {
      console.info("--------------------------");
      console.info("is_NULL =>");
      console.info("--------------------------");
      this.isNullAllValue = true;
    }

    if (this.eoi_form.valid && this.isNullAllValue === false) {
      this.displayEOI = false;
      this.isFormSubmited = false;
      if (eoiType === "buy") {
        this.showBuyDialog();
        this.buy_form.controls["from"].setValue(formData.buy_price);
      } else if (eoiType === "rent") {
        this.showRentDialog();
        this.rent_form.controls["amount_per_week"].setValue(
          formData.rent_per_week
        );
      } else if (eoiType === "short_stay") {
        this.showSortStayDialog();
        this.sort_stay_form.controls["num_days"].setValue(
          formData.short_stay_price_per_night
        );
      } else if (eoiType === "swap") {
        this.showHouseSwapDialog();
      }
    }
    console.info("--------------------------");
    console.info("SUBMIT_EOI_FORM =>", this.eoi_form, formData);
    console.info("--------------------------");
  }

  handleChangeEOICheckbox(value) {
    this.eoi_form.controls["activeEOI"].setValue([value]);
  }

  handleChangeEOIPopup(e, eoi) {
    console.info("--------------------------");
    console.info("event,eoi =>", e, eoi);
    console.info("--------------------------");
    if (e && eoi === "buy") {
      this.displayEOI = false;
      this.showBuyDialog();
    }
    if (e && eoi === "rent") {
      this.displayEOI = false;
      this.showRentDialog();
    }
    if (e && eoi === "short_stay") {
      this.displayEOI = false;
      this.showSortStayDialog();
    }
    if (e && eoi === "swap") {
      this.displayEOI = false;
      this.showHouseSwapDialog();
    }
  }

  showEOIDialog() {
    this.isFormSubmited = false;
    this.eoi_form.reset();
    this.displayEOI = true;
  }

  showBuyDialog() {
    this.isFormSubmited = false;
    this.buy_form.reset();
    this.displayBuy = true;
  }

  showRentDialog() {
    this.isFormSubmited = false;
    this.rent_form.reset();
    this.displayRent = true;
  }

  showSortStayDialog() {
    this.isFormSubmited = false;
    this.sort_stay_form.reset();
    this.displaySortStay = true;
  }

  showHouseSwapDialog() {
    this.isFormSubmited = false;
    this.house_swap_form.reset();
    this.displayHouseSwap = true;
  }

  showMapViewDialog(view) {
    this.activeMapTab = view;
    this.handleChangeMapViewTab(view);
    this.displayMapDialog = true;
  }

  handleChangeMapViewTab(tab: string) {
    this.activeMapTab = tab;
    const fenway = { lat: this.lat, lng: this.long };
    console.log("activeMapTab", this.activeMapTab);

    if (tab === "mapview") {
      setTimeout(() => {
        const map = new google.maps.Map(
          document.getElementById("dialog-mapview-map") as HTMLElement,
          {
            center: fenway,
            zoom: 17,
            streetViewControl: false,
            clickableIcons: true,
            mapTypeControl: false,
          }
        );
        new google.maps.Marker({
          position: fenway,
          map,
          title: this.propertyDetails.address,
          place: this.propertyDetails.city,
        });
      }, 1000);
    }
    if (tab === "schools") {
      this.customStyle = [
        {
          featureType: "poi",
          elementType: "all",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "poi.school",
          elementType: "geometry.school",
          stylers: [{ visibility: "on" }],
        },
      ];
      setTimeout(() => {
        const map = new google.maps.Map(
          document.getElementById("dialog-schools-map") as HTMLElement,
          {
            center: fenway,
            zoom: 17,
            streetViewControl: false,
            clickableIcons: true,
            mapTypeControl: false,
            styles: this.customStyle,
          }
        );
        new google.maps.Marker({
          position: fenway,
          map,
          title: this.propertyDetails.address,
          place: this.propertyDetails.city,
        });
      }, 1000);
    }
    if (tab === "shop") {
      this.customStyle = [
        {
          featureType: "poi",
          elementType: "all",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "poi.business",
          elementType: "geometry.business",
          stylers: [{ visibility: "on" }],
        },
      ];
      setTimeout(() => {
        const map = new google.maps.Map(
          document.getElementById("dialog-shop-map") as HTMLElement,
          {
            center: fenway,
            zoom: 16,
            streetViewControl: false,
            clickableIcons: true,
            mapTypeControl: false,
            styles: this.customStyle,
          }
        );
        new google.maps.Marker({
          position: fenway,
          map,
          title: this.propertyDetails.address,
          place: this.propertyDetails.city,
        });
      }, 1000);
    }
    if (tab === "streetview") {
      setTimeout(() => {
        new google.maps.StreetViewPanorama(
          document.getElementById("dialog-street-map") as HTMLElement,
          {
            position: fenway,
            pov: { heading: 165, pitch: 0 },
            zoom: 1,
          }
        );
      }, 1000);
    }
    if (tab === "commute") {
      setTimeout(() => {
        const directionsRenderer = new google.maps.DirectionsRenderer();
        const directionsService = new google.maps.DirectionsService();
        const map = new google.maps.Map(
          document.getElementById("commute-map") as HTMLElement,
          {
            zoom: 14,
            center: fenway,
            streetViewControl: true,
            streetViewControlOptions: {
              position: google.maps.ControlPosition.LEFT_CENTER,
            },
            zoomControl: true,
            zoomControlOptions: {
              position: google.maps.ControlPosition.LEFT_BOTTOM,
            },
            fullscreenControl: true,
            fullscreenControlOptions: {
              position: google.maps.ControlPosition.LEFT_TOP,
            },
          }
        );
        new google.maps.Marker({
          position: fenway,
          map,
          title: "Hello World!",
        });
        directionsRenderer.setMap(map);
        directionsRenderer.setPanel(
          document.getElementById("right-panel") as HTMLElement
        );
      }, 1000);
    }
    if (tab === "demographic") {
      this.customStyle = [
        {
          featureType: "demographic",
          elementType: "all",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "poi.demographic",
          elementType: "demographic.demographic",
          stylers: [{ visibility: "on" }],
        },
      ];
      setTimeout(() => {
        const map = new google.maps.Map(
          document.getElementById("dialog-demographic-map") as HTMLElement,
          {
            center: fenway,
            zoom: 17,
            streetViewControl: false,
            clickableIcons: true,
            mapTypeControl: false,
            styles: this.customStyle,
          }
        );
        new google.maps.Marker({
          position: fenway,
          map,
          title: this.propertyDetails.address,
          place: this.propertyDetails.city,
        });
      }, 1000);
    }
  }

  async ngOnInit() {
    this.getInitStreetMap();
    this.getInitRoadMap();
  }

  async getInitStreetMap() {
    const fenway = { lat: this.lat, lng: this.long };
    console.log("fenway", fenway);
    new google.maps.StreetViewPanorama(
      document.getElementById("cover-street-view") as HTMLElement,
      {
        position: fenway,
        pov: { heading: 165, pitch: 0 },
        zoom: 1,
      }
    );

    let panorama = new google.maps.StreetViewPanorama(
      document.getElementById("popup-cover-street-view") as HTMLElement,
      {
        position: fenway,
        pov: { heading: 165, pitch: 0 },
        zoom: 1,
        fullscreenControl: false,
        motionTrackingControl: false,
        zoomControl: false,
        addressControl: false,
        panControl: false,
        clickToGo: false,
        imageDateControl: false,
      }
    );
    var panoOptions = {
      scrollwheel: false,
      disableDefaultUI: true,
      clickToGo: false,
    };
    panorama.setOptions(panoOptions);
  }

  async getInitRoadMap() {
    const fenway = { lat: this.lat, lng: this.long };
    console.log("fenway", fenway);
    setTimeout(() => {
      const directionsRenderer = new google.maps.DirectionsRenderer();
      const directionsService = new google.maps.DirectionsService();
      const map = new google.maps.Map(
        document.getElementById("cover-map-view") as HTMLElement,
        {
          zoom: 16,
          center: fenway,
          streetViewControl: false,
          clickableIcons: true,
          mapTypeControl: false,
          zoomControl: false,
          fullscreenControl: false,
        }
      );
      new google.maps.Marker({
        position: fenway,
        map,
        title: this.propertyDetails.address,
        place: this.propertyDetails.city,
      });
      directionsRenderer.setMap(map);
    }, 1000);

    // const mapView = new google.maps.Map(
    //   document.getElementById("popup-cover-map-view") as HTMLElement,
    //   {
    //     center: fenway,
    //     zoom: 8,
    //     streetViewControl: false,
    //     clickableIcons: true,
    //     mapTypeControl: false,
    //     fullscreenControl: false,
    //   }
    // );

    // new google.maps.Marker({
    //   position: fenway,
    //   map: mapView,
    //   title: this.propertyDetails.address,
    //   place: this.propertyDetails.city,
    // });

    const schoolsView = new google.maps.Map(
      document.getElementById("popup-cover-schools-view") as HTMLElement,
      {
        center: fenway,
        zoom: 12,
        streetViewControl: false,
        clickableIcons: true,
        mapTypeControl: false,
        fullscreenControl: false,
        styles: [
          {
            featureType: "poi",
            elementType: "all",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "poi.school",
            elementType: "geometry.stroke",
            stylers: [{ visibility: "on" }],
          },
        ],
      }
    );
    new google.maps.Marker({
      position: fenway,
      map: schoolsView,
      title: this.propertyDetails.address,
      place: this.propertyDetails.city,
    });

    new google.maps.Map(
      document.getElementById("popup-cover-commute-view") as HTMLElement,
      {
        zoom: 15,
        center: fenway,
        streetViewControl: false,
        zoomControl: false,
        fullscreenControl: false,
      }
    );
    new google.maps.Map(
      document.getElementById("popup-cover-shop-view") as HTMLElement,
      {
        zoom: 11,
        center: fenway,
        streetViewControl: false,
        zoomControl: false,
        fullscreenControl: false,
        styles: [
          {
            featureType: "poi",
            elementType: "all",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "poi.business",
            elementType: "geometry.stroke",
            stylers: [{ visibility: "on" }],
          },
        ],
      }
    );
    new google.maps.Map(
      document.getElementById("popup-cover-demographic-view") as HTMLElement,
      {
        center: fenway,
        zoom: 14.5,
        streetViewControl: false,
        clickableIcons: true,
        mapTypeControl: false,
        fullscreenControl: false,
        styles: [
          {
            featureType: "poi.government",
            elementType: "geometry",
            stylers: [{ visibility: "on" }],
          },
        ],
      }
    );
  }

  // validation for enquiries
  changeEnquiry() {
    if (this.selectedEnquries.length > 0) {
      this.enquiriesErr = false;
    } else {
      this.enquiriesErr = true;
    }
  }

  // submit request form
  submit(form) {
    this.isFormSubmited = true;
    if (form.valid) {
      if (this.selectedEnquries.length > 0) {
        this.formData.enquiries = this.selectedEnquries;
        this.spinner.show("outer");
        this.service.post("send_customer_enquiry", this.formData).subscribe(
          (res) => {
            this.isFormSubmited = false;
            this.enquiriesErr = false;
            this.selectedEnquries = ["Could I have a price guide?"];
            this.display = false;
            this.formData = {};
            this.spinner.hide("outer");
            this.messageService.add({
              severity: "success",
              summary: "Success Message",
              detail: "Enquiry Sent Successfully",
            });
          },
          (err) => {
            err = err.error;
            this.isFormSubmited = false;
            this.enquiriesErr = false;
            this.selectedEnquries = ["Could I have a price guide?"];
            this.display = false;
            this.formData = {};
            this.spinner.hide("outer");
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: err.message,
            });
          }
        );
      }
    } else {
      console.log("\n in err : ", form);
    }
  }

  submitEOI(form, type) {
    this.isNullAllValue = false;
    console.info("--------------------------");
    console.info("form,type =>", form, type);
    console.info("--------------------------");
    console.info("--------------------------");
    let propertyID = "";
    console.info(
      "this.router =>",
      this.route.params.subscribe((data) => {
        propertyID = data.id;
      })
    );
    console.info("--------------------------");
    this.isFormSubmited = true;
    let formData = form.value;
    let requestData: any = {};
    console.info("--------------------------");
    console.info("BUY_SUBMIT_FORM TYPE =>", type, this.user);
    requestData.interestBy = this.user.data._id;
    requestData.interestType = type;
    requestData.propertyId = propertyID;
    if (type === "eoi") {
      let buy_price = formData.buy_price;
      let rent_per_week = formData.rent_per_week;
      let short_stay_price_per_night = formData.short_stay_price_per_night;
      let swap_per_night = formData.swap_per_night;

      if (
        buy_price === null &&
        rent_per_week === null &&
        short_stay_price_per_night === null &&
        swap_per_night === null
      ) {
        this.isNullAllValue = true;
      }
      let data = {
        buy_price: parseFloat(buy_price),
        rent_per_week: parseFloat(rent_per_week),
        short_stay_price_per_night: parseFloat(short_stay_price_per_night),
        swap_per_night: parseFloat(swap_per_night),
      };
      requestData.interest = data;
    }
    if (type === "buy") {
      let data = {
        price_to: parseFloat(formData.to),
        price_from: parseFloat(formData.from),
        are_you: formData.are_you,
      };
      requestData.interest = data;
    }

    if (type === "rent") {
      let data = {
        amount_per_week: parseFloat(formData.amount_per_week),
        is_move: formData.are_you === "YES" ? true : false,
      };
      requestData.interest = data;
    }
    if (type === "short_stay") {
      let data = {
        to_date: moment(formData.to_date).toISOString(),
        form_date: moment(formData.from_date).toISOString(),
        num_guests: parseInt(formData.num_guests),
        num_days: parseInt(formData.num_days),
      };
      requestData.interest = data;
    }

    if (type === "house_swap") {
      let data = {
        address: formData.address,
        "is-own-property": formData.has_own_property == "true" ? true : false,
      };
      requestData.interest = data;
      console.info(
        "BUY_SUBMIT_FORM =>",
        moment(formData.from_date).toISOString()
      );
      console.info("--------------------------");
    }

    if (form.valid && this.isNullAllValue === false) {
      this.eoiService
        .addPropertyInterest(requestData)
        .subscribe((data: any) => {
          console.info("--------------------------");
          console.info("eoiService =>", data);
          console.info("--------------------------");
          if (data.code === 200) {
            this.initialDialog();
            this.toastr.success(
              "Your EOI request successfully send",
              "Success!",
              {
                timeOut: 3000,
              }
            );
          }
        });
    }
  }

  requestClaimProperty() {
    console.info("--------------------------");
    console.info(
      "CLAIM_PROPERTY_REQUEST_RESPONSE CLAIM_PROPERTY_REQUEST =>",
      this.user.data._id
    );
    console.info("--------------------------");
    // this.propertyService.claimedProperty(this.propertyDetails._id,this.user.data._id).subscribe(data => {
    //   console.info('--------------------------')
    //   console.info('CLAIM_PROPERTY_REQUEST_RESPONSE =>',data)
    //   console.info('--------------------------')
    // })
  }

  ngOnDestroy() {
    this.paramsSub.unsubscribe();
  }

  ngAfterViewInit() {
    this.spinner.hide("outer");
  }

  ngAfterViewChecked() {
    // document
    //   .getElementsByClassName("ui-lightbox-content")
    //   .item(0)
    //   .setAttribute("style", `width: unset;height: unset;`);
  }
  open(index: number): void {
    this._lightbox.open(this.propertyImages, index, {
      wrapAround: true,
      showImageNumberLabel: false,
      disableScrolling: false,
      positionFromTop: 100,
    });
  }

  isFollowing(favDetails = []) {
    if (this.user) {
      let findFav = _filter(favDetails, { fav_by: this.user.data._id });
      if (findFav.length > 0) {
        return findFav[0];
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  followProperty(propertyId, propertyDetails) {
    let userId = this.user.data._id;
    let search_obj = JSON.parse(localStorage.getItem("search_obj"));
    let findFollow = this.isFollowing(propertyDetails.favDetail)
      ? this.isFollowing(propertyDetails.favDetail)
      : null;
    let status = 1;

    if (findFollow) {
      if (findFollow.fav_status === 1) {
        status = 2;
      } else {
        status = 1;
      }
    } else {
      status = 1;
    }

    this.propertyService
      .addToFavouritesProperty(userId, status, propertyId)
      .toPromise()
      .then(async (res: any) => {
        console.info("--------------------------");
        console.info("FOLLOW_PROPERTY_STATUS =>", res);
        console.info("--------------------------");
        if (res.code == 200) {
          if (status === 1) {
            this.toastr.success(
              "Successfully marked property as favourites",
              "Success!",
              {
                timeOut: 3000,
              }
            );
          } else if (status === 2) {
            this.toastr.success(
              "Successfully unmarked property as favourites",
              "Success!",
              {
                timeOut: 3000,
              }
            );
          }
        }
        this.propertyService
          .getPropertyDetails(this.propertyId)
          .subscribe((res: any) => {
            if (res.code == 200) {
              this.propertyDetails = res.data;
            }
          });
      });
  }
}
