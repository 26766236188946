import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import {environment} from "../../../environments/environment"

@Injectable({
  providedIn: 'root'
})
export class EOIService {
  constructor(private http: HttpClient, private router: Router) {
  }

  addPropertyInterest(data) {
    return this.http.post(`${environment.apiUrl}addPropertyInterest`,data,{
      headers: {
        'accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
  }
}
