import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import {environment} from "../../../environments/environment"
@Injectable({
  providedIn: 'root'
})
export class PropertyService {
  public accessToken = '';
  constructor(private http: HttpClient, private router: Router) {
    this.accessToken = atob(localStorage.getItem('accessToken'));
  }

  getProperties(search_obj){
    let data = search_obj ? JSON.stringify(search_obj): localStorage.getItem('search_obj');
    if(data === null){
      this.router.navigate([""]);
    }
    try{
      return this.http.post(`${environment.apiUrl}getOffMarketList`, data,{
          headers: {
              // 'Authorization': `Bearer ${this.accessToken}`,
              'accept': 'application/json',
              'Content-Type': 'application/json'
            }
      });
      // return this.http.post('https://api.domain.com.au/v1/listings/residential/_search', data,{
      //     headers: {
      //         'Authorization': `Bearer ${this.accessToken}`,
      //         'accept': 'application/json',
      //         'Content-Type': 'application/json'
      //       }
      // });
    }catch(e){
        console.log('\n Err : ', e);
    }
  }

  getPropertyDetails(property_id){
    try {
      return this.http.post(`${environment.apiUrl}getpropertyDetailByid`, {id: property_id },{
        headers: {
            // 'Authorization': `Bearer ${this.accessToken}`,
            'accept': 'application/json',
            'Content-Type': 'application/json'
          }
    });
      // return this.http.get(`https://api.domain.com.au/v1/listings/${property_id}`, {
      //   headers: {
      //     'Authorization': `Bearer ${this.accessToken}`,
      //     'accept': 'application/json',
      //     'Content-Type': 'application/json'
      //   }
      // });
    } catch (e) {
      console.log('\n Err : ', e);
    }
  }

  getAgencyDetails(agency_id){
    try {
      return this.http.get(`https://api.domain.com.au/v1/agencies/${agency_id}`, {
        headers: {
          'Authorization': `Bearer ${this.accessToken}`,
          'accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });
    } catch (e) {
      console.log('\n Err : ', e);
    }
  }

  claimedProperty(requestData){
    try{
      return this.http.post(`${environment.apiUrl}addClaimImage`,requestData,{
        headers: {
          'Authorization': `Bearer ${this.accessToken}`
        }
      });
    }catch(e){
      console.log('\n Err : ', e);
    }
  }

  updatePropertyClaimById(requestData){
    try{
      return this.http.post(`${environment.apiUrl}updateClaimDetails`,requestData,{
        headers: {
          'Authorization': `Bearer ${this.accessToken}`
        }
      });
    }catch(e){
      console.log('\n Err : ', e);
    }
  }

  addSavedSearchProperty(searchPayload,logiUserId){
    try{
      return this.http.post(`${environment.apiUrl}addSavedSearchProperty`,{payload:searchPayload,userId: logiUserId});
    }catch(e){
      console.log('\n Err : ', e);
    }
  }

  getSavedSearchProperties(logiUserId){
    try{
      return this.http.post(`${environment.apiUrl}getListOfSavedSearch`,{userId: logiUserId});
    }catch(e){
      console.log('\n Err : ', e);
    }
  }

  deleteSavedSearch(searchId){
    try{
      return this.http.post(`${environment.apiUrl}deleteSavedSearch`,{id: searchId});
    }catch(e){
      console.log('\n Err : ', e);
    }
  }

  addToFavouritesProperty(userId,status = 1, propertyId){
    try{
      return this.http.post(`${environment.apiUrl}addToFavouritesProperty`,{fav_by: userId, fav_to_property: propertyId, fav_status: status});
    }catch(e){
      console.log('\n Err : ', e);
    }
  }

  getFaviouratePropertyList(userId,status = 1){
    try{
      return this.http.post(`${environment.apiUrl}getFaviouratePropertyList`,{user_id : userId, fav_status: status});
    }catch(e){
      console.log('\n Err : ', e);
    }
  }

  addToFavouritesUser(requestData){
    try{
      return this.http.post(`${environment.apiUrl}addToFavouritesUser`,requestData);
    }catch(e){
      console.log('\n Err : ', e);
    }
  }

}
