import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
//import { formatDate } from "ngx-bootstrap";
import { FileDetector } from "protractor";
import { DataShareService } from "../shared/data-share.service";
import { EncrDecrService } from "../shared/EncrDecrService.service";
import { PropertyService } from "../shared/property.service";
import { FileUploader } from "ng2-file-upload";
import { NgxSpinnerService } from "ngx-spinner";
import { FileHandle } from "src/app/directories/dragDrop.directive";
import { DomSanitizer } from "@angular/platform-browser";
import { ToastrService } from "ngx-toastr";

const URL = "http://192.168.100.23:5094/api/addClaimImage";

@Component({
  selector: "app-claim-ownership-wizard",
  templateUrl: "./claim-ownership-wizard.component.html",
  styleUrls: ["./claim-ownership-wizard.component.css"],
})
export class ClaimOwnershipWizardComponent implements OnInit {
  public user: any;
  public propertyId;
  public step1 = false;
  public step2 = false;
  public step3 = false;
  public step4 = false;
  public step5 = false;
  public expressionText = "CLAIM THIS AS YOUR PROPERTY";
  public isFormSubmited = false;

  public files: File[] = [];
  public dfiles: File[] = [];

  public ownerDetailsForm: FormGroup = new FormGroup({});
  public photoIDForm: FormGroup = new FormGroup({});

  public uploader: FileUploader;
  public hasBaseDropZoneOver: boolean;
  public hasAnotherDropZoneOver: boolean;
  public response: string;
  public selectedPhotoID: any;
  public selectedOwnerDoc: any;

  constructor(
    private route: ActivatedRoute,
    public fb: FormBuilder,
    private propertyService: PropertyService,
    private dataShare: DataShareService,
    private EncrDecr: EncrDecrService,
    private spinner: NgxSpinnerService,
    private sanitizer: DomSanitizer,
    private toastr: ToastrService
  ) {
    this.dataShare.loginUser.subscribe((data) => {
      if (data) {
        console.log("1 => ");
        this.user = localStorage.getItem("user")
          ? this.EncrDecr.getEncrypt(localStorage.getItem("user"))
          : null;
        console.log("this.user => ", this.user);
      } else {
        this.user = localStorage.getItem("user")
          ? this.EncrDecr.getEncrypt(localStorage.getItem("user"))
          : null;
        console.log("this.user => ", this.user);
      }
    });
    this.route.params.subscribe((params) => {
      this.propertyId = params.id;
      console.info("--------------------------");
      console.info("params =>", params);
      console.info("--------------------------");
    });

    this.ownerDetailsForm = this.fb.group({
      is_sole_owner: new FormControl(
        Boolean,
        Validators.compose([Validators.required])
      ),
      first_name: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      last_name: new FormControl("", Validators.compose([Validators.required])),
      email: new FormControl("", Validators.compose([Validators.required])),
      phone_number: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
    });

    this.uploader = new FileUploader({
      url: URL,
      disableMultipart: true, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
      formatDataFunctionIsAsync: true,
      formatDataFunction: async (item) => {
        console.info("--------------------------");
        console.info("item =>", item);
        console.info("--------------------------");
        return new Promise((resolve, reject) => {
          resolve({
            name: item._file.name,
            length: item._file.size,
            contentType: item._file.type,
            date: new Date(),
          });
        });
      },
    });

    this.hasBaseDropZoneOver = false;
    this.hasAnotherDropZoneOver = false;

    this.response = "";

    this.uploader.response.subscribe((res) => (this.response = res));
  }

  handleChangeFile(event, i) {
    console.info("--------------------------");
    console.info("FILE_EVENT =>", event.target.files);
    console.info("--------------------------");
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }

  ngOnInit() {}

  initialSteps() {
    this.step1 = false;
    this.step2 = false;
    this.step3 = false;
    this.step4 = false;
    this.step5 = false;
  }

  onSelect(event: any) {
    console.info("--------------------------");
    console.info("onSelect =>", event.target.files);
    console.info("--------------------------");
    const file = event.target.files[0];
    this.selectedPhotoID = this.sanitizer.bypassSecurityTrustUrl(
      window.URL.createObjectURL(file)
    );
    if (file) {
      this.files.push(file);
    }
    // files.forEach((item) => {
    // });
  }
  onDocSelect(event: any) {
    console.info("--------------------------");
    console.info("onSelect =>", event.target.files);
    console.info("--------------------------");
    const file = event.target.files[0];
    if (!file.type.includes("image/")) {
      this.dfiles.push(file);
      this.selectedOwnerDoc = this.sanitizer.bypassSecurityTrustUrl(
        window.URL.createObjectURL(file)
      );
      console.log(this.selectedOwnerDoc, this.dfiles);
    } else {
      // this.dfiles = [];
      this.toastr.error("Invalid File Type");
    }
    // files.forEach((item) => {
    // });
  }

  induct_rec_file_uplaod(file, index): void {
    let fileList = [];
    fileList.push(file);
    if (fileList.length > 0) {
      const file = fileList[0];
      const fileExt = file.name.split(".").pop();
      const file_collection = this.files;

      if ("csv" !== fileExt.toLowerCase()) {
        if (file_collection && file_collection[index]) {
          delete file_collection[index];

          this.files = [];
          file_collection.forEach((f_data, i) => {
            this.files.push(f_data);
          });
        }
      } else {
        if (file_collection && file_collection[index]) {
          file_collection[index] = file;

          this.files = [];
          file_collection.forEach((f_data, i) => {
            this.files.push(file);
          });
        } else {
          this.files.push(file);
        }
      }
    } else {
      const file_collection = this.files;
      if (file_collection && file_collection[index]) {
        file_collection[index] = null;

        this.files = [];
        file_collection.forEach((f_data, i) => {
          if (f_data) {
            this.files.push(f_data);
          }
        });
      }
    }
    console.info("--------------------------");
    console.info("this.files =>", this.files);
    console.info("--------------------------");
  }

  onRemove(event) {
    // console.log(event, this.files);
    this.selectedPhotoID = null;
    this.files = [] || null;
    // this.files.splice(this.files.indexOf(event), 1);
  }

  onSelectDocumentFile(files: FileHandle[]) {
    console.log(files[0].file);
    const file = files[0].file;
    if (!file.type.includes("application/csv")) {
      this.dfiles.push(file);
      this.selectedOwnerDoc = files[0].url;
    } else {
      // this.dfiles = [];
      this.toastr.error("Invalid File Type");
    }
    // event.addedFiles.forEach((item) => {
    //   this.dfiles.push(item);
    // });
    console.info("--------------------------");
    console.info("this.dfiles =>", this.dfiles);
    console.info("--------------------------");
  }

  onRemoveDocumentFile(event) {
    // console.log(event);
    // this.dfiles.splice(this.dfiles.indexOf(event), 1);
    this.selectedOwnerDoc = null;
    this.dfiles = [] || null;
  }

  showWizard() {
    this.initialSteps();
    this.step1 = true;
  }

  showStep1() {
    this.initialSteps();
    this.step1 = true;
  }
  showStep2() {
    this.initialSteps();
    this.step2 = true;
  }
  showStep3() {
    this.isFormSubmited = true;
    console.info("--------------------------");
    console.info("SUBMIT_FORM_2 =>", this.ownerDetailsForm);
    console.info("--------------------------");
    if (this.ownerDetailsForm.valid === true) {
      this.isFormSubmited = false;
      this.initialSteps();
      this.step3 = true;
    }
  }
  showStep4() {
    if (this.files.length >= 1) {
      this.initialSteps();
      this.step4 = true;
    }
  }
  showStep5() {
    this.spinner.show("outer");
    let uploadImages = [];
    let uploadProof = [];

    let fd = new FormData();
    this.files.forEach((item) => {
      fd.append("file", item, item.name);
    });

    let fdata = new FormData();
    this.dfiles.forEach((item) => {
      fdata.append("file", item, item.name);
    });

    this.propertyService.claimedProperty(fd).subscribe((res: any) => {
      if (res.code === 200) {
        uploadImages = res.data;

        this.propertyService.claimedProperty(fdata).subscribe((res: any) => {
          if (res.code === 200) {
            uploadProof = res.data;

            let requestObject = {
              ...this.ownerDetailsForm.value,
              photoIds: uploadImages,
              proof_of_ownership: uploadProof,
              userId: this.user.data._id,
              propertyId: this.propertyId,
              isStatus: "new",
            };

            this.propertyService
              .updatePropertyClaimById(requestObject)
              .subscribe((res) => {
                this.spinner.hide("outer");
                this.initialSteps();
                console.info("--------------------------");
                console.info("updatePropertyClaim =>", res);
                console.info("--------------------------");
                this.dataShare.toggleDisplayClaimButton(false);
                this.step5 = true;
              });
          }
        });
      }
    });
  }
  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  filesDropped(file: FileHandle[]): void {
    console.log(file[0].file);
    this.selectedPhotoID = file[0].url;
    if (file[0].file) {
      this.files.push(file[0].file);
    }
  }
}
